import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';

import environment from '@app/env';
import { tie, defer } from '@app/dom';

const app = initializeApp(environment.firebase);
const analytics = getAnalytics(app);
defer(() => {
  fillUserProperties();

  environment.firebaseEvents.forEach((event) => {
    fillEventParams(event.params);

    logEvent(analytics, event.name, event.params);
  });

  function fillUserProperties() {
    setUserProperties(analytics, {
      user_type: environment.userType,
    });
  }

  function fillEventParams(analyticsParams) {
    analyticsParams.uid = environment.uid;
    analyticsParams.market = environment.market;
    analyticsParams.timestamp = Date.now();

    if (environment.loggedInAs) {
      analyticsParams.is_admin = true;
    }

    for (let paramName in environment.firebaseOptionalParams) {
      analyticsParams[paramName] = environment.firebaseOptionalParams[paramName];
    }
  }

  tie.bind('[data-analytics-event]', (element) => {
    const analyticsEvent = element.dataset.analyticsEvent;
    let analyticsParams = element.dataset.analyticsParams;

    if (analyticsParams) {
      analyticsParams = JSON.parse(analyticsParams);
    } else {
      analyticsParams = {};
    }

    fillEventParams(analyticsParams);

    function onClick() {
      if (!analyticsEvent) {
        return;
      }

      const count = countSelectedItems();
      if (count) {
        analyticsParams.count = count;
      }

      logEvent(analytics, analyticsEvent, analyticsParams);
    }

    function countSelectedItems() {
      const selectElement = document.querySelector('select[data-analytics-propagate-selected-count]');

      if (selectElement) {
        return selectElement.querySelectorAll('option:checked').length;
      }

      return null;
    }

    element.addEventListener('click', onClick);

    return () => {
      element.removeEventListener('click', onClick);
    };
  });
});
